import React, {useEffect, useRef, useState} from 'react';
import './CFAdminVehicleConfig.scss'
import {Formik} from "formik";
import * as Yup from "yup";
import VehicleConfigService from "../../services/VehicleConfigService";
import {FVPSelect, FVPTextInputWithSize} from "../../components/formField";
import CFAdminModal from "../../components/CFAdminModal";
import CFAdminVehicleConfigForm from "./CFAdminVehicleConfigForm";
import CFAdminNotification from "../../components/CFAdminNotification";
import {DiscardedVehicleType, DualListBoxType, VehicleDetailsProps} from "./types";
import DualListBox from "react-dual-listbox";
import CFAdminSystemSuggestedVehicles from "./CFAdminSystemSuggestedVehicles";
import AddIcon from "../../assets/images/add-icon.svg";
import CFAdminUnSupportedVehicles from "./CFAdminUnSupportedVehicles";

type OptionType = { description: string; code?: string };

const schema = Yup.object().shape({
    make: Yup.string()
        .required("Make is a required field"),
    model: Yup.string()
        .required("Model is a required field"),
    modelDesc: Yup.string(),
    year: Yup.string()
        .required("Year is a required field"),
});

function CFAdminVehicleConfig({screen}: any) {

    const [vehicleDetails, setVehicleDetails] = useState<VehicleDetailsProps>({
        make: '',
        model: '',
        modelDesc: '',
        year: '',
        attributeIds: [],
        attributeIdValues: []
    });
    const childCompRef = useRef<any>();
    const [makesOptions, setMakesOptions] = useState<OptionType[]>([]);
    const [modelsOptions, setModelsOptions] = useState<OptionType[]>([]);
    const [yearsOptions, setYearsOptions] = useState<OptionType[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [suggestedModelOptions, setSuggestedModelOptions] = useState<OptionType[]>([]);
    const [suggestedAttributeOptions, setSuggestedAttributeOptions] = useState<DualListBoxType[]>([]);
    const [selectedSuggestedAttributes, setSelectedSuggestedAttributes ] = useState([]);
    const [isSelectModal, setIsSelectModal] = useState(true);
    const [initialModalDesc, setInitialModalDesc] = useState('');
    const [suggestedVehiclesData, setSuggestedVehiclesData] = useState<any>([]);
    const [unsupportedVehicles, setUnsupportedVehicles] = useState<any>([]);


    const suggestedVehiclesColumns = [
        {
            Header: "Year",
            accessor: 'year',
            disableSortBy: true,
        },
        {
            Header: "Make",
            accessor: 'make',
            disableSortBy: true,
        },
        {
            Header: "Model",
            accessor: 'model',
            disableSortBy: true,
        },
        {
            Header: "Actions - Generate New Configurations",
            Cell: (props: any) => {
                return (
                <div>
                    <img className="hover-cursor" title="Generate New Config" src={AddIcon} data-testid={"modal-delete"+props.row.original.make} onClick={() => handleGenerate(props)}
                       alt="Xcelvations Logo" height="15"/>
                </div>
            )
            },
        }
    ];

    const handleGenerate = (props: any) => {
        vehicleDetails.make = props.row.original.make;
        vehicleDetails.model = props.row.original.model;
        vehicleDetails.year = props.row.original.year;
        toggleModal();
    }

    const getVehicleMakes = async () => {
        VehicleConfigService.getVehicleMakes()
            .then(response => {
                const responseModel = response.data?.response;
                if (responseModel) {
                    setMakesOptions(responseModel);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getVehicleModels = async () => {
        VehicleConfigService.getVehicleModels(vehicleDetails.make, vehicleDetails.year)
            .then(response => {
                const responseModel = response.data?.response;
                if (responseModel) {
                    setModelsOptions(responseModel);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getVehicleYears = async () => {
        VehicleConfigService.getVehicleYears()
            .then(response => {
                const responseModel = response.data?.response;
                if (responseModel) {
                    setYearsOptions(responseModel);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getSystemSuggestedModels= async () => {

        VehicleConfigService.getSystemSuggestedModels()
            .then(response => {
                const responseModel = response.data?.suggestedModels;
                if (responseModel) {

                    let options : OptionType[] = [];
                    responseModel.forEach((element: any) => {
                        let option: OptionType = {
                            description: String(element.make+'-'+element.model),
                            code: element.modelId!
                        };

                        options.push(option);
                    });
                    setSuggestedModelOptions(options);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getVinMappedAttributes = async () => {
        VehicleConfigService.getVinMappedAttributes()
            .then(response => {
                const responseModel = response.data?.attributes;
                if (responseModel) {
                    let attributes: DualListBoxType[] = [];
                    responseModel.forEach((element: any) => {
                        let option: DualListBoxType = {
                            value: String(element.attributeId!),
                            label: element.attributeDescription!
                        };
                        attributes.push(option);
                    });
                    setSuggestedAttributeOptions(attributes);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const onChangeMake = async (e: any) => {
        vehicleDetails.make = e.target.value;
        if(vehicleDetails.make !== '' && vehicleDetails.year !== ''){
            setModelsOptions([]);
            getVehicleModels();
        }
    };

    const onChangeModel = async (e: any) => {
        vehicleDetails.model = e.target.value;
    };

    const onChangeModelDesc = async (e: any) => {
        vehicleDetails.modelDesc = e.target.value;
        setInitialModalDesc(e.target.value);
    };

    const onRadioSelectModel = async (e: any) => {
        //setFieldValue('modelDesc', '');
        vehicleDetails.model = '';
        vehicleDetails.modelDesc = '';
        setInitialModalDesc('');
        if(e.target.value === '0'){
            setIsSelectModal(true);
        }else{
            setIsSelectModal(false);
        }
    };

    const onChangeSuggestedModel = async (e: any) => {
        vehicleDetails.model = e.target.value;
    };

    const onChangeYear = async (e: any) => {

        vehicleDetails.year = e.target.value;

        if(vehicleDetails.make !== '' && vehicleDetails.year !== ''){
            setModelsOptions([]);
            getVehicleModels();
        }
    };

    const handleSubmit = (values: any) => {
        toggleModal();
    };

    const getSystemSuggestedVehicles = () => {
            VehicleConfigService.getSystemSuggestedVehicles()
                .then(response => {

                    const responseModel = response.data?.suggestedVehicles;
                    if (responseModel) {

                        setSuggestedVehiclesData(responseModel);
                    }
                })
                .catch(e => {
                    confirmErrorMessage("Error in getSystemSuggestedVehicles!");
                });
    }

    const getUnSupportedVehicles = () => {
        VehicleConfigService.getUnSupportedVehicles()
            .then(response => {

                const responseModel = response.data?.unsupportedVehicles;
                if (responseModel) {
                    setUnsupportedVehicles(responseModel);
                }
            })
            .catch(e => {
                confirmErrorMessage("Error in getUnSupportedVehicles!");
                console.error(e);
            });
    };


    const handleSelectedVehicles = (selectedRows: any) => {
        //alert(id+'-'+values);
        let discardVehicles: DiscardedVehicleType[]  = [];
        selectedRows.map((row : any ,index : any)=>{
            discardVehicles.push(row.original);
            return discardVehicles;
        });
        VehicleConfigService.discardVehicles({discardVehicles})
            .then(response => {

                getSystemSuggestedVehicles();
         }).catch(e => {
                confirmErrorMessage("Error in discardVehicles!");
            console.error(e);
         });
    };

    const handleSupportedVehicles = (selectedRows: any) => {
        //alert(id+'-'+values);
        let modelYearIds: any[]  = [];
        selectedRows.map((row : any ,index : any)=>{
            modelYearIds.push(row.original.modelYearId);
            return modelYearIds;
        });
        let supportedVehicles = {
            modelYearIds: modelYearIds
        };
        VehicleConfigService.supportVehicles(supportedVehicles)
            .then(response => {
                getUnSupportedVehicles();
            }).catch(e => {
            confirmErrorMessage("Error in supportVehicles!");
            console.error(e);
        });
    };

    useEffect(() => {
        setMakesOptions([]);
        setYearsOptions([]);
        setModelsOptions([]);
        if(screen==='create' || screen==='edit'){
            getVehicleMakes();
            getVehicleYears();
        }else if(screen === 'suggestedconfig'){
            getSystemSuggestedModels();
            getVinMappedAttributes();
        }else if(screen === 'suggestedveh'){
            getSystemSuggestedVehicles();
        }else if(screen === 'unsupportvehicle'){
            getUnSupportedVehicles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screen]);

    const toggleModal = () => {
        if(screen==='create' || screen==='edit' || screen==='suggestedveh' || screen==='unsupportvehicle'){
            if(vehicleDetails.make === '' || (vehicleDetails.model === '' && vehicleDetails.modelDesc === '') || vehicleDetails.year === ''){
                confirmErrorMessage("Please select Make, Model and Year");
                return;
            }
        }else{
            if(vehicleDetails.model === '' || selectedSuggestedAttributes.length === 0){
                confirmErrorMessage("Please select Model and Attributes");
                return;
            }
        }

        setVehicleDetails({
            ...vehicleDetails,
            make: vehicleDetails.make,
            model: vehicleDetails.model,
            modelDesc: vehicleDetails.modelDesc,
            year: vehicleDetails.year,
            attributeIds: selectedSuggestedAttributes,
            attributeIdValues: []
        });

        setIsOpen(!isOpen);
    }

    const closeModal = () => {
        setIsOpen(!isOpen);
        if(screen === 'suggestedveh'){
            getSystemSuggestedVehicles();
        }else if(screen === 'unsupportvehicle') {
            getUnSupportedVehicles();
        }
    }

    const confirmSuccessMessage = async (value: any) => {
        childCompRef.current.successMessage(value);
    }
    const confirmErrorMessage = async (value: any) => {
        childCompRef.current.failureMessage(value);
    };


    return (
        <>
            <div className="config-component">
            <h2 className="fomo-type--title">
                {screen==='create' && 'Create New Vehicle Configurations'}
                {screen==='edit'&&'Manage Vehicle Configurations'}
                {screen==='suggestedconfig'&&'Manage Configurations'}
                {screen==='suggestedveh'&&'Review New Vehicles'}
                {screen === 'unsupportvehicle' && 'Review UnSupported Vehicles'}
            </h2>
            <Formik
                validationSchema={schema}
                initialValues={{ make: vehicleDetails.make, modelDesc: ''  }}
                onSubmit={handleSubmit}
            >
                {({values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, dirty}) => (
                    <div className="fvp-form">
                        <div className="modal-top-div">
                            <form noValidate onSubmit={handleSubmit}>
                                <div className="modal-body">
                                    <div className="fvp-form__group">
                                        <div className="active-step">
                                            {(screen === 'create' || screen === 'edit') && <>
                                                <div className="fvp-form__subgroup">
                                                    <div>&nbsp;</div>
                                                    <FVPSelect
                                                        style={{width: "200px"}}
                                                        label="Make:"
                                                        name="makesOptions"
                                                        data-testid="make"
                                                        //value={values.initialValues.make}
                                                        onChange={onChangeMake}
                                                        options={makesOptions}
                                                        required
                                                    />

                                                    <FVPSelect
                                                        style={{width: "200px"}}
                                                        label="Year:"
                                                        name="yearsOptions"
                                                        data-testid="year"
                                                        onChange={onChangeYear}
                                                        options={yearsOptions}
                                                        required
                                                    />
                                                </div>
                                                <div className="fvp-form__subgroup">
                                                    <input type="radio" name="modelSelectRadio" value="0"
                                                           checked={isSelectModal}
                                                           onClick={onRadioSelectModel}
                                                           onChange={e => {}}
                                                           data-testid="modelSelectRadio"
                                                           id="modelSelect"/>
                                                    <FVPSelect
                                                        style={{width: "200px"}}
                                                        label="Model:"
                                                        name="modelsOptions"
                                                        data-testid="model"
                                                        onChange={onChangeModel}
                                                        options={modelsOptions}
                                                        disabled={!isSelectModal}
                                                        required
                                                    />
                                                </div>
                                                <div className="fvp-form__subgroup">
                                                    <input type="radio" name="modelTextRadio" value="1"
                                                           checked={!isSelectModal}
                                                           data-testid="modelTextRadio"
                                                           onChange={e => {}}
                                                           onClick={onRadioSelectModel}
                                                           id="modelText"/>
                                                    <FVPTextInputWithSize size={50} label="Description:" name="modelDesc"
                                                                          value = {initialModalDesc}
                                                                          onChange={onChangeModelDesc}
                                                                          required={!isSelectModal} disabled={isSelectModal}/>
                                                </div>
                                            </>}
                                            {(screen === 'suggestedconfig') && <>
                                                <div className="fvp-form__subgroup">
                                                    <FVPSelect
                                                        style={{width: "200px"}}
                                                        label="Model:"
                                                        name="makeModelsOptions"
                                                        data-testid="makeModels"
                                                        //value={values.initialValues.make}
                                                        onChange={onChangeSuggestedModel}
                                                        options={suggestedModelOptions}
                                                        required
                                                    />
                                                </div>
                                                <div className="fvp-form__subgroup">
                                                <div className="ext4">
                                                        Attributes
                                                        <div className="ext4">
                                                            <DualListBox
                                                                options={suggestedAttributeOptions}
                                                                selected={selectedSuggestedAttributes}
                                                                onChange={(newValue: any) => {
                                                                    setSelectedSuggestedAttributes(newValue);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            }
                                            {(screen === 'create' || screen === 'edit' || screen === 'suggestedconfig') &&
                                            <div>
                                                <button data-testid="submit" className="fvp-button button-right-align"
                                                        onClick={toggleModal}>
                                                    View Configuration <i className="fvp-right-chevron"/>
                                                </button>
                                            </div>
                                            }
                                            {(screen === 'suggestedveh') &&
                                                <>
                                                    <CFAdminSystemSuggestedVehicles
                                                        columnValues = {suggestedVehiclesColumns}
                                                        dataValues = {suggestedVehiclesData}
                                                        handleSelectedVehicles={handleSelectedVehicles}
                                                        />
                                                </>
                                            }
                                            {(screen === 'unsupportvehicle') &&
                                                <>
                                                    <CFAdminUnSupportedVehicles
                                                        columnValues = {suggestedVehiclesColumns}
                                                        dataValues = {unsupportedVehicles}
                                                        handleSupportedVehicles={handleSupportedVehicles}
                                                    />
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </Formik>
                <CFAdminModal
                    isOpen={isOpen}
                    toggle={toggleModal}
                    isAdd={true}
                    title={ (screen === 'create' || screen==='suggestedveh' || screen==='unsupportvehicle' ) ? 'Generate Configurations' : 'Manage Vehicle Configs'}
                >
                    <CFAdminVehicleConfigForm
                        screen={screen}
                        vehicleDetails={vehicleDetails}
                        toggleModal={toggleModal}
                        closeModal={closeModal}
                        successMessage={confirmSuccessMessage}
                        errorMessage={confirmErrorMessage}/>
            </CFAdminModal>
            <CFAdminNotification ref={childCompRef}/>
            </div>
        </>
    );
}

export default CFAdminVehicleConfig;