import axios from "axios";

const url = window.location.href;

let BASE_URL: string | undefined = "";
if(url.includes("www."))
  BASE_URL = process.env.REACT_APP_BASE_URL_PROD;
else if(url.includes("wwwqa."))
  BASE_URL = process.env.REACT_APP_BASE_URL_QA
else
  BASE_URL = process.env.REACT_APP_BASE_URL_DEV

const http = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-type": "application/json"
  }
});

//request interceptor
http.interceptors.request.use((config) => {
      document.body.style.cursor = 'wait';
      return config;
    }
);

//response interceptor
http.interceptors.response.use(
  response => {
    document.body.style.cursor = 'default';
    return response
  },
  function (error) {
    document.body.style.cursor = 'default';
      console.error("interceptor ::: ");
    return Promise.reject(error)
  }
);

export default http;