import React, {useEffect, useRef, useState} from 'react';
import {Formik} from "formik";
import {FVPSelect} from "../../components/formField";
import * as Yup from "yup";
import VehicleConfigService from "../../services/VehicleConfigService";
import RecommendationService from "../../services/RecommendationService";
import './CFAdminCloneVehicle.scss'
import CFAdminNotification from "../../components/CFAdminNotification";
import CFAdminConfirmDialog from "../../components/CFAdminConfirmDialog";
import {VehicleAttributeType} from "../CFAdminVehicleConfig/types";
import CFAdminModal from "../../components/CFAdminModal";
import CFAdminVehicleConfigDetails from "./CFAdminVehicleConfigDetails";
import useVehicleState from "../../hooks/useVehicleState";
import {handleResponseCloneAndCopyVehicle} from "../../util/handleResponseCloneAndCopyVehicle";
import {getYearModels, getVehicleYears, getToYearModels} from "../../util/vehicleServiceUtils";

const schema = Yup.object().shape({
    fromModel: Yup.string(),
    fromYear: Yup.string(),
    toModel: Yup.string(),
    toYear: Yup.string(),
});

function CFAdminCloneVehicle() {
    const childCompRef = useRef<any>();
    const [cloneVehicle, setCloneVehicle] = useState<any>({
        fromModel: '',
        fromYear: '',
        toModel: '',
        toYear: '',
    });
    const {
        yearsOptions, setYearsOptions,
        toYearsOptions, setToYearsOptions,
        fromModelsOptions, setFromModelsOptions,
        toModelsOptions, setToModelsOptions,
        fromModelSelectValue, setFromModelSelectValue,
        toModelSelectValue, setToModelSelectValue,
        fromYearSelectValue, setFromYearSelectValue,
        toYearSelectValue, setToYearSelectValue,
        isFromYearSelected, setIsFromYearSelected,
        isFromModelSelected, setIsFromModelSelected,
        isToYearSelected, setIsToYearSelected,
        modelYearResponsesList, setModelYearResponsesList,
        isConfirmOpen, setIsConfirmOpen,
        confirmDescription, setConfirmDescription,
        fromColumnValues, setFromColumnValues,
        fromDataValues, setFromDataValues,
        fromTotalRecords, setFromTotalRecords,
        toColumnValues, setToColumnValues,
        toDataValues, setToDataValues,
        toTotalRecords, setToTotalRecords
    } = useVehicleState();

    const [isOpen, setIsOpen] = useState(false);
    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(0);
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const handleSubmit = async () => {
        console.log(cloneVehicle);
        if (cloneVehicle.fromModel === '' || cloneVehicle.fromYear === '' || cloneVehicle.toModel === '' || cloneVehicle.toYear === '') {
            confirmErrorMessage("Please select Source and Destination values.");
            return;
        }

        if (cloneVehicle.fromModel === cloneVehicle.toModel) {
            confirmErrorMessage("Source and Destination should not be the same.");
            return;
        }

        await setDescription();
        await getVehicleConfigs(cloneVehicle.fromModel, true);
        await getVehicleConfigs(cloneVehicle.toModel, false, () => {
            setIsSubmitButtonClicked(isSubmitButtonClicked +1);
            setIsButtonClicked(true);
        });
    };

    const isSubmitDisabled = () => {
        return !toModelSelectValue || !toYearSelectValue || !fromModelSelectValue || !fromYearSelectValue;
    };

    const handleConfirmSubmit = async () => {
        setIsOpen(false);
        setIsConfirmOpen(true);
    };

    const setDescription = async () => {
        const fromYearOption = yearsOptions.find(option => String(option.code) === cloneVehicle.fromYear);
        const toYearOption = toYearsOptions.find(option => String(option.code) === cloneVehicle.toYear);
        const fromModelOption = fromModelsOptions.find(option => String(option.code) === cloneVehicle.fromModel);
        const toModelOption = toModelsOptions.find(option => String(option.code) === cloneVehicle.toModel);
        setConfirmDescription("from " +
            fromYearOption?.description + " " +
            fromModelOption?.description + " " +
            " to " +
            toYearOption?.description + " " +
            toModelOption?.description);
    };

    const cloneVehiclesOperation = () => {
        const request = {
            "fromModelYearId": +cloneVehicle.fromModel,
            "toModelYearId": +cloneVehicle.toModel
        };
        RecommendationService.cloneVehicles(request)
            .then(response => {
                handleResponseCloneAndCopyVehicle(
                    response,
                    setIsConfirmOpen,
                    confirmSuccessMessage,
                    confirmErrorMessage,
                    "Vehicle configurations and maintenance recommendations copied " + confirmDescription,
                    "Error while Copying Vehicles " + confirmDescription
                );
                onSubmitCloneVehicle();
            })
            .catch(e => {
                console.log(e);
                setIsConfirmOpen(false);
                confirmErrorMessage("Error while Cloning Vehicles " +confirmDescription);
            });
    }

    useEffect(() => {
        getVehicleYears(setYearsOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getVehicleConfigs = async (modelYearId: any, isFrom: boolean, callback?: () => void) => {
        return VehicleConfigService.getVehicleConfigurationsWithId(modelYearId)
            .then(response => {
                const responseModel = response.data?.vehicles;
                if (responseModel) {
                    let columns: any = [];
                    let data: any = [];
                    let count: number = 0;
                    let isColAdded: boolean = false;
                    responseModel.forEach((parentElement: any) => {
                        count = count + 1;
                        if (!isColAdded) {
                            columns.push({ Header: '', accessor: 'id', disableSortBy: true });
                            parentElement.vehicleAttributes.forEach((childElement: VehicleAttributeType) => {
                                columns.push({
                                    Header: () => (
                                        <div className="flex">
                                            <div className="inline-flex">{childElement.attributeDescription}</div>
                                        </div>
                                    ),
                                    disableSortBy: true,
                                    accessor: 'attributeValueDescription' + childElement.attributeId
                                });
                            });
                            isColAdded = true;
                        }
                        data.push(createJson(count, parentElement.vehicleId, parentElement.vehicleAttributes));
                    });
                    if (isFrom) {
                        setFromColumnValues(columns);
                        setFromDataValues(data);
                        setFromTotalRecords(count);
                    } else {
                        setToColumnValues(columns);
                        setToDataValues(data);
                        setToTotalRecords(count);
                    }
                    if (callback) {
                        callback();
                    }
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    useEffect(() => {

        if (isSubmitButtonClicked && isButtonClicked) {
            if (toTotalRecords > 0 && fromTotalRecords > 0) {
                setIsOpen(true);
            } else {
                if(fromTotalRecords === 0)
                    confirmErrorMessage("Source Vehicle must have at least 1 configuration present.");
                else if(toTotalRecords === 0)
                    confirmErrorMessage("Destination Vehicle must have at least 1 configuration present.");

                setIsOpen(false);
            }
        }
    }, [toTotalRecords,isSubmitButtonClicked, isButtonClicked, fromTotalRecords]);


    function createJson(count:number, vehicleId: number, childElements: any)
    {
        let jsonData : any = {};
        childElements.forEach((childElement: VehicleAttributeType) => {
            let columnName = 'attributeValueDescription'+childElement.attributeId;
            jsonData[columnName] = childElement.attributeValueDescription;
        });
        jsonData['id'] = 'Configuration ' + count;
        jsonData['vehicleId'] = vehicleId;

        return jsonData;
    }

    const confirmSuccessMessage = async (value: any) => {
        childCompRef.current.successMessage(value);
    };

    const confirmErrorMessage = async (value: any) => {
        childCompRef.current.failureMessage(value);
    };

const resetCloneVehicleState = () => {

    setFromModelsOptions([]);
    setToModelsOptions([]);
    setToYearsOptions([]);
    setFromModelSelectValue('');
    setToYearSelectValue('');
    setToModelSelectValue('');
    setIsFromYearSelected(false);
    setIsFromModelSelected(false);
    setIsToYearSelected(false);
};

const onChangeFromYear = async (e: any) => {
    cloneVehicle.fromYear = e.target.value;
    setFromYearSelectValue(e.target.value);
    setFromModelsOptions([]);
    setToModelsOptions([]);
    setToYearsOptions([]);
    setFromModelSelectValue('');
    setToYearSelectValue('');
    setToModelSelectValue('');
    getYearModels(
        cloneVehicle.fromYear,
        yearsOptions,
        setIsFromYearSelected,
        setFromModelsOptions,
        setToYearsOptions,
        setModelYearResponsesList
    );
    resetCloneVehicleState();
};

const onSubmitCloneVehicle = async () => {
    setFromYearSelectValue('Select');
    resetCloneVehicleState();
};

    const onChangeToYear = async (e: any) => {
        cloneVehicle.toYear = e.target.value;
        setToYearSelectValue(e.target.value);
        setToModelsOptions([]);
        cloneVehicle.toModel = '';
        setCloneVehicle({
            ...cloneVehicle,
            toModel: '',
        });
        setToModelSelectValue('');
        await getToYearModels(
            cloneVehicle.toYear,
            modelYearResponsesList,
            setIsToYearSelected,
            setToModelsOptions
        );

    };

    const onChangeFromModel = async (e: any) => {
        cloneVehicle.fromModel = e.target.value;
        setFromModelSelectValue(e.target.value);
        setIsFromModelSelected(true);
        setToYearSelectValue('');
        setToModelSelectValue('');
        setIsToYearSelected(false);

    };

    const onChangeToModel = async (e: any) => {
        cloneVehicle.toModel = e.target.value;
        setToModelSelectValue(e.target.value);
        setIsOpen(false);
        setToTotalRecords(0);
        setFromTotalRecords(0);
        setIsButtonClicked(false);
    };

    function getDescriptions(isFromSet: boolean): string {
        const fromYearOption = yearsOptions.find(option => String(option.code) === fromYearSelectValue);
        const toYearOption = toYearsOptions.find(option => String(option.code) === toYearSelectValue);
        const fromModelOption = fromModelsOptions.find(option => String(option.code) === fromModelSelectValue);
        const toModelOption = toModelsOptions.find(option => String(option.code) === toModelSelectValue);
        const fromMake = fromModelOption?.make;

        if (isFromSet) {
            return fromMake + ' ' + (fromYearOption?.description || '') + ' ' + (fromModelOption?.description || '');
        } else {
            return fromMake + ' ' + (toYearOption?.description || '') +  ' ' +  (toModelOption?.description || '');
        }
    }

    const toggleModal = () => {
        setIsOpen(!isOpen);
    }

    return (


        <>
            <div className="make-component">
                <h2 className="fomo-type--title">Clone Vehicle and Recommendations</h2>
                <Formik
                    validationSchema={schema}
                    initialValues={{fromModel: '', fromYear: ''}}
                    onSubmit={handleSubmit}
                >
                    {({values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, dirty}) => (
                        <div className="fvp-form">
                            <div className="modal-top-div">
                                <form noValidate onSubmit={handleSubmit}>
                                    <div className="modal-body">
                                        <div className="fvp-form__group">
                                            <div className="active-step">
                                                <>
                                                    <div className="ext1">
                                                        <div className="ext1-1">
                                                            * Source Year should be Less than or Equal to the Destination Year<br/>
                                                            * The destination Vehicle must have at least 1 configuration
                                                            present.<br/>
                                                            * All configurations and recommendations on the destination
                                                            vehicle will be removed. This information is not
                                                            recoverable.
                                                        </div>
                                                        <>
                                                            <div className="container">
                                                                <div className="box">
                                                                    <div className="box-row">
                                                                        <div className="box-cell box1">
                                                                            <div className="rcorners3">
                                                                                <center>
                                                                                    <b>From/Source</b>
                                                                                </center>
                                                                                <div className="fvp-form__subgroup">
                                                                                    <FVPSelect
                                                                                        label="Year:"
                                                                                        name="fromYear"
                                                                                        value={fromYearSelectValue}
                                                                                        data-testid="fromyear"
                                                                                        onChange={onChangeFromYear}
                                                                                        options={yearsOptions}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                <div className="fvp-form__subgroup">
                                                                                    <FVPSelect
                                                                                        label="Model:"
                                                                                        name="fromModel"
                                                                                        data-testid="frommodel"
                                                                                        value={fromModelSelectValue}
                                                                                        onChange={onChangeFromModel}
                                                                                        options={fromModelsOptions}
                                                                                        disabled={!isFromYearSelected}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="box-cell box1">
                                                                            <div className="fvp-form__subgroup">
                                                                                &nbsp;&nbsp;&nbsp;
                                                                            </div>
                                                                            <div className="fvp-form__subgroup">
                                                                                &nbsp;&nbsp;&nbsp;
                                                                            </div>
                                                                            <div className="fvp-form__subgroup">
                                                                                <center>
                                                                                    <b className="box-cell box3">Clone</b>
                                                                                </center>

                                                                            </div>
                                                                        </div>
                                                                        <div className="box-cell box1">
                                                                            <div className="rcorners3">
                                                                                <center>
                                                                                    <b>To/Destination</b>
                                                                                </center>
                                                                                <div className="fvp-form__subgroup">
                                                                                    <FVPSelect
                                                                                        label="Year:"
                                                                                        name="toYear"
                                                                                        value={toYearSelectValue}
                                                                                        data-testid="toyear"
                                                                                        onChange={onChangeToYear}
                                                                                        options={toYearsOptions}
                                                                                        disabled={!isFromModelSelected}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                <div className="fvp-form__subgroup">
                                                                                    <FVPSelect
                                                                                        label="Model:"
                                                                                        name="toModel"
                                                                                        data-testid="tomodel"
                                                                                        value={toModelSelectValue}
                                                                                        onChange={onChangeToModel}
                                                                                        options={toModelsOptions}
                                                                                        disabled={!isToYearSelected}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </div>
                                                </>
                                                <div className="right-align">
                                                    <button data-testid="submit-next"
                                                            className="fvp-button button-right-align" type="button"
                                                            onClick={() => handleSubmit()}
                                                            disabled={isSubmitDisabled()}>
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
            <CFAdminModal
                isOpen={isOpen}
                toggle={toggleModal}
                isAdd={true}
                title={'Vehicle Configs'}
            >

                <CFAdminVehicleConfigDetails
                    fromColumnValues={fromColumnValues}
                    fromDataValues={fromDataValues}
                    fromTotalRecords={fromTotalRecords}
                    toColumnValues={toColumnValues}
                    toDataValues={toDataValues}
                    toTotalRecords={toTotalRecords}
                    fromColumnDesc={getDescriptions(true)}
                    toColumnDesc={getDescriptions(false)}
                    handleConfirmSubmit={handleConfirmSubmit}/>
            </CFAdminModal>

            <CFAdminNotification ref={childCompRef}/>
            <CFAdminConfirmDialog
                isOpen={isConfirmOpen}
                onClose={()=>setIsConfirmOpen(false)}
                onConfirm={cloneVehiclesOperation}
                title={"Vehicle configurations and maintenance recommendations will be copied " +confirmDescription}
                text="Are you sure you want to clone?"
            />
        </>
    );
}

export default CFAdminCloneVehicle;