import React, {useEffect, useState} from "react";
import VehicleConfigService from "../../services/VehicleConfigService";
import CFAdminDataTable from "../../components/CFAdminDataTable";
import {VehicleAttributeType} from "./types";
import { CellAnchorButton } from "../../components/CFAdminDataTable/ActionsButtonComponents";

function CFAdminSystemSuggestedDetails ({vehicleDetails, switchToEdit, errorMessage} : any) {

    const [columnValues, setColumnValues] = useState<any>([]);
    const [dataValues, setDataValues] = useState<any>([]);

    const handleYearClick = async (event: any) => {

        vehicleDetails.make = event.originalRow.make
        vehicleDetails.model = event.originalRow.model
        vehicleDetails.year = event.originalRow.id
        switchToEdit();
    };

    const renderIdButton = (props: any, handleChange: Function) => {
        return <CellAnchorButton {...props} handleChange={handleChange}/>
    }

    const getSystemSuggestedVehicles = () => {
        let data = {
            "modelId": +vehicleDetails.model,
            "attributeIds": [+vehicleDetails.attributeIds]
        };
        VehicleConfigService.getSystemSuggestedConfigurations(data)
            .then(response => {

                    const responseModel = response.data?.systemSuggestedConfigByYear;
                    if (responseModel) {

                        let columns : any = [];
                        let data : any = [];
                        let count: number = 0;
                        let isColAdded : boolean = false;
                        responseModel.forEach((parentElement: any) => {
                            count = count+1;
                            if(!isColAdded) {
                                columns.push({Header: "yearId", enableRowSpan: true,
                                    disableSortBy: true, accessor:'yearId', Cell: (props: any) => {
                                        return (
                                            <div>
                                                {renderIdButton(props, handleYearClick)}
                                            </div>
                                        )},});
                                parentElement.suggestedConfigurations.forEach((childElement: VehicleAttributeType) => {
                                    columns.push({
                                        Header: childElement.attributeDescription,
                                        disableSortBy: true,
                                        accessor: 'attributeValueDescription'+childElement.attributeId})
                                });
                                isColAdded = true;
                            }
                            data.push(createJson(count, parentElement, parentElement.suggestedConfigurations));
                        });
                        setColumnValues(columns);
                        setDataValues(data);
                    }
                })
                .catch(e => {
                    errorMessage("Error in getSystemSuggestedConfigurations!");
                    console.error(e);
                });
    }

    function createJson(count:number, parentElement: any, childElements: any)
    {
        let jsonData : any = {};
        childElements.forEach((childElement: VehicleAttributeType) => {
            let columnName = 'attributeValueDescription'+childElement.attributeId;
            jsonData[columnName] = childElement.attributeValueDescription;
        });
        jsonData['id'] = parentElement.year;
        jsonData['yearId'] = parentElement.yearId;
        jsonData['make'] = parentElement.make;
        jsonData['makeId'] = parentElement.makeId;
        jsonData['model'] = parentElement.model;
        jsonData['modelId'] = parentElement.modelId;
        return jsonData;
    }


    useEffect(() => {
        getSystemSuggestedVehicles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="ext5">
                {}
            </div>
            <div className="ext2">
                * {} vehicle configurations were found for the vehicle listed below
            </div>

            <>
                <CFAdminDataTable
                    columns={columnValues}
                    data={dataValues}
                    textModal={''}
                    showFilter={false}
                    showAdd={false}
                    showPagination={false}
                    pageSize={1000}
                />
            </>
        </>
    )
}
export default CFAdminSystemSuggestedDetails;