import { authHeader } from "../util/auth-header";
import http from "../util/http-common";

export class RecommendationService {

  static getAttributes() {
    return http.get("/cfadminvehicleconfig/api/v1/attributes/for-recommendations",{ headers: authHeader()});
  }

  static getAttributeValues(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/attribute-values-query/for-recommendations",data,{ headers: authHeader()});
  }

  static createBulkBundle(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/vehicle-bundles-bulk",data,{ headers: authHeader()});
  }

  static deleteBulkBundle(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/vehicle-bundles-bulk/delete",data,{ headers: authHeader()});
  }

  static createBulkOperation(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/vehicle-operation-bulk",data,{ headers: authHeader()});
  }

  static deleteBulkOperation(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/vehicle-operation-bulk/delete",data,{ headers: authHeader()});
  }

  static getYearModels(year: string) {
    return http.get(`/cfadminvehicleconfig/api/v1/mmy/model-years-gte?year=${year}`,{ headers: authHeader()});
  }

  static cloneVehicles(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/vehicle/clone-vehicles",data,{ headers: authHeader()});
  }

  static copyVehicles(data:any) {
    return http.post("/cfadminvehicleconfig/api/v1/vehicle/copy-vehicle-recommendations",data,{ headers: authHeader()});
  }

  static getIolmIntervals() {
    return http.get(`/cfadminvehicleconfig/api/v1/vehicle-driving-conditions-query/iolm-multiple-intervals`,{ headers: authHeader()});
  }

}

export default RecommendationService;