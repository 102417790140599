import VehicleConfigService from '../services/VehicleConfigService';
import RecommendationService from "../services/RecommendationService";


type OptionType = { description: string; code?: string, make?: string };

export const getVehicleYears = async (setYearsOptions: (options: OptionType[]) => void) => {
    try {
        const response = await VehicleConfigService.getVehicleYears();
        const responseModel = response.data?.response;
        let yearOptions: OptionType[] = [];
        if (responseModel) {
            responseModel.forEach((element: any) => {
                let data: OptionType = {
                    description: element.description,
                    code: element.id
                };
                yearOptions.push(data);
            });
            setYearsOptions(yearOptions);
        }
    } catch (e) {
        console.log(e);
    }
};

export const getYearModels = async (
    yearId: any,
    yearsOptions: OptionType[],
    setIsFromYearSelected: (value: boolean) => void,
    setFromModelsOptions: (options: OptionType[]) => void,
    setToYearsOptions: (options: OptionType[]) => void,
    setModelYearResponsesList: (list: any) => void
) => {
    const selectedYearOption = yearsOptions.find(option => String(option.code) === yearId);
    RecommendationService.getYearModels(selectedYearOption?.description ?? '')
        .then(response => {
            const responseModel = response.data?.modelYearResponsesList;
            console.log(':::responseModel:::', responseModel);
            let modelOptions: OptionType[] = [];
            let toYearOptions: OptionType[] = [];
            if (responseModel) {
                responseModel.forEach((modelYear: any) => {
                    console.log(yearId, ':::modelYear:::', modelYear.yearId);
                    if (modelYear.yearId === Number(yearId)) {
                        console.log('::in :modelYear:::', modelYear.year);
                        modelYear.models.forEach((element: any) => {
                            let data: OptionType = {
                                description: element.model,
                                code: element.modelYearId,
                                make: modelYear.make
                            };
                            modelOptions.push(data);
                        });
                    }
                    let dataToYear: OptionType = {
                        description: modelYear.year,
                        code: modelYear.yearId
                    };
                    toYearOptions.push(dataToYear);
                });
                setIsFromYearSelected(true);
                setFromModelsOptions(modelOptions);
                setToYearsOptions(toYearOptions);
                setModelYearResponsesList(responseModel);
                console.log("working from year calll")
            }
        })
        .catch(e => {
            console.log(e);
        });
};

export const getToYearModels = async (
    yearId: any,
    modelYearResponsesList: any[],
    setIsToYearSelected: (value: boolean) => void,
    setToModelsOptions: (options: OptionType[]) => void
) => {
    let toYearModelsOptions: OptionType[] = [];
    if (modelYearResponsesList != null && modelYearResponsesList.length > 0) {
        modelYearResponsesList.forEach((toModelYear: any) => {
            console.log(yearId, ':::modelYear:::', toModelYear.yearId);
            if (toModelYear.yearId === Number(yearId)) {
                console.log('::in :modelYear:::', toModelYear.year);
                toModelYear.models.forEach((element: any) => {
                    let data: OptionType = {
                        description: element.model,
                        code: element.modelYearId,
                    };
                    toYearModelsOptions.push(data);
                });
            }
        });
        setIsToYearSelected(true);
        setToModelsOptions(toYearModelsOptions);
    }
};