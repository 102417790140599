import React, {useEffect, useRef, useState} from 'react';
import CFAdminDataTable from "../../components/CFAdminDataTable";
import CFAdminModal from "../../components/CFAdminModal";
import './CFAdminDrivingConds.scss'
import CFAdminDrivingCondForm from './CFAdminDrivingCondForm';
import DrivingConditionService from '../../services/DrivingConditionService';
import CFAdminNotification from '../../components/CFAdminNotification';
import {CountryLanguageEnum} from "../../constants/CountryLanguageEnum";
import {ActionMode} from "../../constants/ActionMode";
import {DrivingCondition, DrivingConditionLanguage} from "./types";
import ActionButtons from "../../util/renderActionButtons";

function CFAdminDrivingConds() {
    const childCompRef = useRef<any>();
    const [data, setData] = useState([] as DrivingCondition[]);
    const [isDelete, setIsDelete] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [actionText, setActionText] = useState("Add Driving Condition");
    const [initialValues, setInitialValues] = useState(
        {drivingConditionId: 0, mode: ActionMode.Add, drivingConditionSortKey: 0,
            adminDesc: "", adminDescId:0,
            adminUsSpaDesc: "", adminUsSpaDescId: 0,
            adminCanEngDesc: "", adminCanEngDescId: 0,
            adminCanFrDesc:"", adminCanFrDescId:0, userKey:"", modified:""});

    const columns = [
        {
            Header: "Id",
            accessor: 'drivingConditionId',
            Cell: ({value}: any) => <div className="cell-max-row-height">{value}</div>
        },
        {
            Header: "Description",
            accessor:  (row: DrivingCondition) => {
                const drivingConditionLanguage: DrivingConditionLanguage | undefined = row.drivingConditionsLanguageList.find(dcLang => dcLang.countryLanguageId === CountryLanguageEnum.US_ENG);
                return drivingConditionLanguage ? drivingConditionLanguage.description : '';
            },
            Cell: ({ value }: any) => <div className="cell-left-justified">{value}</div>
        },
        {
            Header: "Last Modified By",
            accessor: 'userKey'
        },
        {
            Header: "Last Modified Date",
            accessor: 'modified'
        },
        {
            Header: "Actions",
            Cell: (props:any) => (
                <ActionButtons
                    props={props}
                    handleChange={handleChange}
                    handleDelete={handleDelete}
                />
            ),
        }
    ];

    const handleChange = (event: any) => {
        const drivingConditionLanguageUsEng: DrivingConditionLanguage | undefined = event.originalRow.drivingConditionsLanguageList.find((opLang: { countryLanguageId: CountryLanguageEnum; }) => opLang.countryLanguageId === CountryLanguageEnum.US_ENG);
        const drivingConditionLanguageUsSpa: DrivingConditionLanguage | undefined = event.originalRow.drivingConditionsLanguageList.find((opLang: { countryLanguageId: CountryLanguageEnum; }) => opLang.countryLanguageId === CountryLanguageEnum.US_SPA);
        const drivingConditionLanguageCanEng: DrivingConditionLanguage | undefined = event.originalRow.drivingConditionsLanguageList.find((opLang: { countryLanguageId: CountryLanguageEnum; }) => opLang.countryLanguageId === CountryLanguageEnum.CAN_ENG);
        const drivingConditionLanguageCanFra: DrivingConditionLanguage | undefined = event.originalRow.drivingConditionsLanguageList.find((opLang: { countryLanguageId: CountryLanguageEnum; }) => opLang.countryLanguageId === CountryLanguageEnum.CAN_FRA);

        setInitialValues({
            ...initialValues,
            mode: ActionMode.Edit,
            drivingConditionId: event.originalRow.drivingConditionId,
            drivingConditionSortKey: event.originalRow.drivingConditionSortKey,
            adminDesc: drivingConditionLanguageUsEng ? drivingConditionLanguageUsEng.description : '',
            adminDescId:drivingConditionLanguageUsEng ? drivingConditionLanguageUsEng.languageTextId : 0,
            adminUsSpaDesc: drivingConditionLanguageUsSpa ? drivingConditionLanguageUsSpa.description : '',
            adminUsSpaDescId: drivingConditionLanguageUsSpa ? drivingConditionLanguageUsSpa.languageTextId : 0,
            adminCanEngDesc: drivingConditionLanguageCanEng ? drivingConditionLanguageCanEng.description : '',
            adminCanEngDescId: drivingConditionLanguageCanEng ? drivingConditionLanguageCanEng.languageTextId : 0,
            adminCanFrDesc: drivingConditionLanguageCanFra ? drivingConditionLanguageCanFra.description : '',
            adminCanFrDescId: drivingConditionLanguageCanFra ? drivingConditionLanguageCanFra.languageTextId : 0,
            userKey:"",
            modified: ""
        });
        setIsOpen(!isOpen);
    }

    const handleDelete = (event: any) => {
        setActionText("Delete Driving Condition");
        setIsDelete(true);
        handleChange(event);
    }

    useEffect(() => {
        retrieveDrivingConditions();
    }, []);


    const retrieveDrivingConditions = async () => {
        DrivingConditionService.getAll()
            .then(response => {
                const drivingConditions = response.data.drivingConditionResponses as DrivingCondition[];
                setData(drivingConditions.sort((a: DrivingCondition, b: DrivingCondition) => b.drivingConditionSortKey - a.drivingConditionSortKey));
            })
            .catch(e => {
                console.error(e);
            });
    };

    const confirmSuccessMessage = async (value: any) => {
        childCompRef.current.successMessage(value);
    };
    const confirmErrorMessage = async (value: any) => {
        childCompRef.current.failureMessage(value);
    };


    const toggleModal = () => {
        setInitialValues({
            ...initialValues,
            mode: ActionMode.Add,
            drivingConditionId: 0,
            drivingConditionSortKey: 0,
            adminDesc: "", adminDescId:0,
            adminUsSpaDesc: "", adminUsSpaDescId: 0,
            adminCanEngDesc: "", adminCanEngDescId: 0,
            adminCanFrDesc:"", adminCanFrDescId:0,
            userKey: "",
            modified: ""
        });
        setIsOpen(!isOpen);
        setActionText("Add Driving Condition");
        setIsDelete(false);
    }

    return (

        <div className="drivingConditions-component">
            <h2 className="fomo-type--title">Driving Conditions List</h2>
            <CFAdminModal
                isOpen={isOpen}
                toggle={toggleModal}
                isAdd={true}
                title={actionText}
            >
                <CFAdminDrivingCondForm {...initialValues}
                                      isDelete={isDelete}
                                      toggleModal={toggleModal}
                                      refreshData={retrieveDrivingConditions}
                                      successMessage={confirmSuccessMessage}
                                      errorMessage={confirmErrorMessage}/>
            </CFAdminModal>

            <CFAdminDataTable
                columns={columns}
                data={data}
                toggleModal={toggleModal}
                textModal={'Add Driving Condition'}
            />
            <CFAdminNotification ref={childCompRef}/>
        </div>
    );
}

export default CFAdminDrivingConds;