import React from 'react';

function CFAdminBulkBundleConfirmation ({activeStep, bulkBundleConfigs, setBulkBundleConfigs, selectedAttributeValuesData, attributeValuesOptions,
                                            disableSaveAndDelete,selectedAttributesData = [],getAttributeLabel} : any) {

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!disableSaveAndDelete) {
            setBulkBundleConfigs({
                ...bulkBundleConfigs,
                updateOperations: event.target.checked
            });
        }
    };

    return (
        <>
            <div className="scroll">
                {(
                    <div className="ext1-11">
                        {bulkBundleConfigs.bundleIds &&
                            <> <b> Recommended Bundles</b> <br/> <b>Selected Bundles</b></>}
                        <br/>{bulkBundleConfigs.bundleIds && bulkBundleConfigs.bundleIds.map((bundles: any) => {
                        return <> {bundles.label}<br/></>
                    })}
                        {bulkBundleConfigs.operationIds &&
                            <> <b> Recommended Operations</b> <br/> <b>Selected Operations</b></>}
                        <br/>{bulkBundleConfigs.operationIds && bulkBundleConfigs.operationIds.map((bundles: any) => {
                        return <> {bundles.label}<br/></>
                    })}
                        {bulkBundleConfigs.drivingConditionIds && <b>Selected Driving Conditions</b>}
                        <br/>{bulkBundleConfigs.drivingConditionIds && bulkBundleConfigs.drivingConditionIds.map((bundles: any) => {
                        return <> {bundles.label} <br/></>
                    })}
                        {bulkBundleConfigs.intervalIds && <b>Selected Mileage Intervals</b>}
                        <br/>{bulkBundleConfigs.intervalIds && bulkBundleConfigs.intervalIds.map((bundles: any) => {
                        return <> {bundles.label} <br/></>
                    })}
                        {activeStep >= 3 && selectedAttributesData.length > 0 && (
                            <>
                                <b>Selected Attributes</b>
                                <br/>
                                {selectedAttributesData.map((attribute: any) => (
                                    <> {getAttributeLabel(attribute)}<br/></>
                                ))}
                            </>
                        )}
                        {selectedAttributeValuesData.size > 0 && <b>Selected Vehicles</b>}
                        <br/>{selectedAttributeValuesData && Array.from(selectedAttributeValuesData.entries()).map((entry) => {
                        const [key, value] = entry as [string, string[]];
                        const foundOption = attributeValuesOptions.get(key);
                        const displayLabel: string[] = [];

                        if (foundOption && Array.isArray(foundOption)) {
                            foundOption.forEach((option: any) => {
                                (value.toString()).split(',').forEach((val: any) => {
                                    if (option.value === val.toString()) {
                                        displayLabel.push(option.label);
                                    }
                                });
                            });
                        }

                        return (
                            <div key={key}>
                                <b>{key}</b> : {displayLabel.join(",")}
                            </div>
                        );
                    })}
                    </div>
                )}
            </div>
            {activeStep === 5 && <div>
                <br/>Apply Changes to Vehicle Bundle Operations
                <input type="checkbox" id="selectAll" name="selectAll" value="selectAll"
                       onChange={handleCheckboxChange} disabled={disableSaveAndDelete} defaultChecked={true}/>
                <br/>Please uncheck to apply bundle recommendations only.
            </div>}
        </>
    )
}

export default CFAdminBulkBundleConfirmation