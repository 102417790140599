import React, {useEffect, useRef, useState} from 'react';
import Stepper from "react-stepper-horizontal";
import './CFAdminVehicleBundle.scss'
import CFAdminNotification from "../../components/CFAdminNotification";
import VehicleConfigService from "../../services/VehicleConfigService";
import {FVPSelect} from "../../components/formField";
import {Formik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import {MultiSelectOption} from "../CFAdminBulkBundle";
import OperationService from "../../services/OperationService";
import {OperationLanguage} from "../CFAdminOperations/types";
import {CountryLanguageEnum} from "../../constants/CountryLanguageEnum";
import BundleService from "../../services/BundleService";

type OptionType = { description: string; code?: string };
type CFAdminVehicleBundleProps = {
    yearId: any;
};

const schema = Yup.object().shape({
    year: Yup.string()
        .required("Year is a required field"),
});

function CFAdminVehicleBundle({screen}: any) {

    const childCompRef = useRef<any>();
    const [activeStep, setActiveStep] = useState(0);
    const [nextStep, setNextStep] = useState(0);
    const [stepperSteps, setStepperSteps] = useState<any>([]);
    const [yearsOptions, setYearsOptions] = useState<OptionType[]>([]);
    const [operationOptions, setOperationOptions] = useState<MultiSelectOption[]>([]);
    const [selectedOperationOptions, setSelectedOperationOptions] = useState<any[]>([]);
    const [bundleOptions, setBundleOptions] = useState<MultiSelectOption[]>([]);
    const [selectedBundleOptions, setSelectedBundleOptions] = useState<any[]>([]);

    const [vehicleDetails, setVehicleDetails] = useState<CFAdminVehicleBundleProps>({
        yearId: '',
    });

    const getStepperSteps = () => {

        let steps = [];
        setActiveStep(0);
        setNextStep(0);

        if(screen==='vehiclebundle'){
            steps.push({title: 'Year and Bundles', onClick: () => setActiveStep(1)});
        }else{
            steps.push({title: 'Year and Operation', onClick: () => setActiveStep(1)});
        }
        steps.push({title: 'Existing Vehicles', onClick: () => setActiveStep(2)});
        steps.push({title: 'Confirm Recommendations', onClick: () => setActiveStep(3)});
        setStepperSteps(steps);

    };


    const handleStepperNext = (nextStep: number) => {
        setActiveStep(nextStep);
    };

    useEffect(() => {
        getSectionComponent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, nextStep]);

    useEffect(() => {
        getStepperSteps();
        setYearsOptions([]);
        getVehicleYears();
        if(screen==='vehiclebundle'){
            getVehicleBundles();
        }else if(screen==='vehicleoperation'){
            getVehicleOperations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screen]);

    const onChangeYear = async (e: any) => {
        setVehicleDetails({
            ...vehicleDetails,
            yearId: e.target.value,
        });
    };

    const getVehicleBundles = async () => {
        BundleService.getAll()
            .then(response => {
                const vehicleBundlesResponseModel = response.data?.bundlesResponseModel;
                if (vehicleBundlesResponseModel) {
                    let vehicleBundleOptions : MultiSelectOption[] = [];
                    vehicleBundlesResponseModel.forEach((element: any) => {
                        //console.log('element response:', element);
                        let bundleData : MultiSelectOption = { label: element.bundleDescription, value:element.id};
                        vehicleBundleOptions.push(bundleData);
                    });
                    setBundleOptions(vehicleBundleOptions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getVehicleOperations = async () => {
        OperationService.getAll()
            .then(response => {
                console.log('getOperations response:', response);
                const vehicleOperationList = response.data?.operationList;
                if (vehicleOperationList) {
                    let vehicleOperationOptions : MultiSelectOption[] = [];
                    vehicleOperationList.forEach((element: any) => {
                        const operationLanguage: OperationLanguage | undefined = element.operationLanguageList.find((opLang: { countryLanguageId: CountryLanguageEnum; }) => opLang.countryLanguageId === CountryLanguageEnum.US_ENG);
                        let operationData : MultiSelectOption = { label: operationLanguage ? operationLanguage.description + '('+ element.operationCode + ')' : '',
                            value:element.operationId};
                        vehicleOperationOptions.push(operationData);
                    });
                    setOperationOptions(vehicleOperationOptions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getVehicleYears = async () => {
        VehicleConfigService.getVehicleYears()
            .then(response => {
                const responseModel = response.data?.response;
                if (responseModel) {
                    setYearsOptions(responseModel);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    function getSectionComponent() {
        if(activeStep === 0) {

        }
        handleStepperNext(nextStep);
    }

    const resetForm = () => {
        setActiveStep(0);
        setNextStep(0);
    };

    const handlePreviousClick = () => {
        setNextStep(activeStep - 1);
    };

    const handleNextClick = () => {
        setNextStep(activeStep + 1);
    };

    const createBulkBundle = async () => {

    };

    const createBulkOperation = async () => {

    };

    const onChangeOperation = async (selectedOptions: any) => {
        console.log('onChangeOperation:', selectedOptions);
        setSelectedOperationOptions(selectedOptions);
    };

    const onChangeBundle = async (selectedOptions: any) => {
        console.log('onChangeBundle:', selectedOptions);
        setSelectedBundleOptions(selectedOptions);
    };

    const handleSubmit = (values: any) => {

    };

    return (
        <>
            <div className="config-component">
                {screen === 'vehiclebundle' && <h2 className="fomo-type--title">Manage Bundles Recommendations</h2>}
                {screen === 'vehicleoperation' && <h2 className="fomo-type--title">Manage Operations Recommendations</h2>}
            </div>
                <Formik
                validationSchema={schema}
                initialValues={{ yearId: vehicleDetails.yearId  }}
                onSubmit={handleSubmit}
            >
                {({values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, dirty}) => (
                    <div className="stepper fvp-form">
                        <Stepper steps={stepperSteps} activeStep={activeStep}/>
                        <div className="modal-top-div">
                            <form noValidate onSubmit={handleSubmit}>
                                <div>
                                    <div className="fvp-form__group">
                                        <div className="active-step">
                                            {(screen === 'vehiclebundle' || screen === 'vehicleoperation') && activeStep === 0 && <>
                                                <div className="fvp-form__subgroup">
                                                    <div>&nbsp;</div>
                                                    <div>
                                                        <FVPSelect
                                                            style={{width: "200px"}}
                                                            label="Year:"
                                                            name="yearsOptions"
                                                            data-testid="year"
                                                            onChange={onChangeYear}
                                                            options={yearsOptions}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="ext1-11">

                                                    </div>
                                                </div>
                                            </>}
                                            {(screen === 'vehiclebundle' && activeStep === 0 ) && <>
                                                <div className="fvp-form__subgroup">
                                                    <div>&nbsp;</div>
                                                    <div className="ext1-11">
                                                        <b> Please select the bundles you want to recommend.</b>
                                                        <Select
                                                            isMulti
                                                            name="bundle"
                                                            options={bundleOptions}
                                                            value={selectedBundleOptions}
                                                            onChange={onChangeBundle}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            closeMenuOnSelect={false}
                                                        />
                                                    </div>
                                                </div>
                                            </>}
                                            {(screen === 'vehicleoperation' && activeStep === 0 ) && <>
                                                <div className="fvp-form__subgroup">
                                                    <div>&nbsp;</div>
                                                    <div className="ext1-11">
                                                        <b> Please select the operations you want to recommend.</b>
                                                        <Select
                                                            isMulti
                                                            name="operation"
                                                            options={operationOptions}
                                                            value={selectedOperationOptions}
                                                            onChange={onChangeOperation}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            closeMenuOnSelect={false}
                                                        />
                                                    </div>
                                                </div>
                                            </>}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer button-frame">
                                    <button data-testid="step-cancel" className="fvp-button--outline fvp-button"
                                            type="submit"
                                            onClick={resetForm}>
                                        <i className="fvp-left-chevron"/>Cancel
                                    </button>
                                    <div className="right-align">
                                        {(activeStep !== 0) && (
                                            <button data-testid="step-prev"
                                                    className="fvp-button button-right-align" type="button"
                                                    onClick={handlePreviousClick}>
                                                <i className="fvp-left-chevron"/> Previous
                                            </button>
                                        )}
                                        {activeStep !== stepperSteps.length - 1 && (
                                            <button data-testid="step-next"
                                                    className="fvp-button button-right-align" type="button"
                                                    onClick={() => handleNextClick()}>
                                                Next <i className="fvp-right-chevron"/>
                                            </button>
                                        )}
                                        {activeStep === stepperSteps.length - 1 && (
                                            <div className="button-container">
                                                {(
                                                    <button data-testid="step-submit"
                                                            className="fvp-button button-right-align" type="button"
                                                            onClick={() => screen === 'bulkbundle' ? createBulkBundle() : createBulkOperation()}>
                                                        Save <i className="fvp-right-chevron"/>
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
                </Formik>
            <CFAdminNotification ref={childCompRef}/>
        </>
    );
}

export default CFAdminVehicleBundle;