import React, {useEffect, useState} from "react";
import CFAdminDataSelectTable from "../../components/CFAdminDataTable/CFAdminDataSelectTable";
import {VehicleAttributeCombination, VehicleAttributeType} from "./types";

function CFAdminVehicleConfigSelectDetails ({attributeCombinations, handleSelectedConfigValues, vehicleDetails} : any) {

    const [selectedRows, setSelectedRows] = React.useState<any>([]);
    const [columnValues, setColumnValues] = useState<any>([]);
    const [dataValues, setDataValues] = useState<any>([]);

    function createJson(count:number, vehicleId: number, isSelected: boolean, childElements: any)
    {
        let jsonData : any = {};
        let attributeValueIds : any =[];
        childElements.forEach((childElement: VehicleAttributeType) => {
            let columnName = 'attributeValueDescription'+childElement.attributeId;
            jsonData[columnName] = childElement.attributeValueDescription;
            attributeValueIds.push(Number(childElement.attributeValueId))
        });
        jsonData['id'] = 'Configuration ' + count;
        jsonData['selected'] = isSelected;
        jsonData['vehicleId'] = vehicleId;
        jsonData['attributeValueIds'] = attributeValueIds;
        return jsonData;
    }

    function populateSelectionDetails(attributeCombinations : VehicleAttributeCombination[]){
        if (attributeCombinations) {

            let columns : any = [];
            let data : any = [];
            let count: number = 0;
            let isColAdded : boolean = false;
            attributeCombinations.forEach((parentElement: any) => {
                count = count+1;
                if(!isColAdded) {
                    parentElement.vehicleAttributes.forEach((childElement: VehicleAttributeType) => {
                        columns.push({ //Header: childElement.attributeDescription ,
                            Header: () => (
                                <div className="flex">
                                    <div className="inline-flex">{childElement.attributeDescription}</div>
                                </div>
                            ),
                            disableSortBy: true,
                            accessor: 'attributeValueDescription'+childElement.attributeId})
                    });
                    isColAdded = true;
                }
                data.push(createJson(count, parentElement.vehicleId, parentElement.isQCM, parentElement.vehicleAttributes));
            });
            setColumnValues(columns);
            setDataValues(data);
        }
    }


    useEffect(() => {
        populateSelectionDetails(attributeCombinations);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributeCombinations]);

    React.useEffect(() => {
        handleSelectedConfigValues(selectedRows);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);

    return (
        <>
            <div className="ext1">
                Below are listed all possible configurations based on the attribute values that were selected.
            </div>
            <div className="ext2">
                Please select the checkbox next to all valid configurations for the <b> {vehicleDetails.year + ' ' + vehicleDetails.make + ' ' + vehicleDetails.model} </b> Existing configurations are already checked for you.
            </div>
            <div className="ext3">
                Note: On continue, selected configurations will be saved while unselected configurations will be discarded.
            </div>
            <p>Selected Rows: {Object.keys(selectedRows).length}</p>
            <>
                <CFAdminDataSelectTable
                    columns={columnValues}
                    data={dataValues}
                    textModal={''}
                    showFilter={false}
                    showAdd={false}
                    showPagination={false}
                    showSelect={true}
                    onRowSelect={ (rows : any) => setSelectedRows(rows)}
                    pageSize={1000}
                />
            </>
        </>
    )
}
export default CFAdminVehicleConfigSelectDetails;