import React from 'react';
import { usePagination, useTable, useSortBy, useGlobalFilter, useExpanded, useRowSelect} from 'react-table'
import './CFAdminDataTable.scss'

type DatatableProps = {
    columns: any;
    data:any;
    toggleModal?:any;
    textModal?:any;
    showFilter?:boolean;
    showAdd?:boolean;
    showPagination?: boolean;
    showSelect?: boolean;
    pageSize?: number;
    pageIndex?: number;
    tdClassName?: string;
    onRowSelect?: any;
};

export const CFAdminDataSelectTable = ({
                                     columns,
                                     data,
                                     toggleModal,
                                     textModal,
                                     showFilter = true,
                                     showAdd = true,
                                     showPagination= true,
                                     showSelect = false,
                                     pageSize = 10,
                                     pageIndex = 0,
                                     onRowSelect = [],
                                     tdClassName
                                 }: DatatableProps) => {

    const selectedRowIds = data.map((d: any, ind: any) => d.selected)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        state,
        prepareRow,
        selectedFlatRows,
        setGlobalFilter,
    } = useTable({
            // @ts-ignore
            columns,
            data,
            autoResetGlobalFilter: false,
            initialState: { pageIndex, pageSize, selectedRowIds}
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        hooks => {
            if(showSelect){
                hooks.visibleColumns.push(columns => [
                    // Let's make a column for selection
                    {
                        id: "selection",
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div>
                                <IndeterminateCheckbox name={''} {...getToggleAllRowsSelectedProps()} />
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox
                                    name={''}
                                    {...row.getToggleRowSelectedProps()} />
                            </div>
                        )
                    },
                    ...columns
                ]);
            }
        }
    );

    interface IIndeterminateInputProps {
        indeterminate?: boolean;
        name: string;
    }

    const useCombinedRefs = (...refs: any[]): React.MutableRefObject<any> => {
        const targetRef = React.useRef();

        React.useEffect(() => {
            refs.forEach(ref => {
                if (!ref) return;

                if (typeof ref === 'function') {
                    ref(targetRef.current);
                } else {
                    ref.current = targetRef.current;
                }
            });
        }, [refs]);

        return targetRef;
    };

    const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, IIndeterminateInputProps>(
        ({ indeterminate, ...rest } , ref) => {
            const defaultRef = React.useRef<HTMLInputElement>();
            const resolvedRef = useCombinedRefs(ref, defaultRef);

            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            return (
                <>
                    <input type="checkbox" ref={resolvedRef} {...rest} />
                </>
            );
        }
    );

    // value and onChange function
    const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;
        setGlobalFilter(value);
    };

    const { pageIndex: currentPageIndex, pageSize: currentPageSize } = state

    React.useEffect(() => {
        onRowSelect(selectedFlatRows);
    }, [onRowSelect, selectedFlatRows]);

    return (
        <div >
            <div className='split-screen'>
                {showFilter && (<div className='search-frame'>
                    <input type="search" className='search-input' placeholder="Filter"
                           onChange={handleFilterInputChange}/>
                </div>)}
                {showAdd && (<div style={{display: textModal}}>
                    <button data-testid="add-button"
                            className="fvp-button"
                            onClick={toggleModal}
                    >
                        {textModal}
                    </button>
                </div>)}
            </div>

            <div className="table">
                <table {...getTableProps()}>
                    <thead>

                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                return (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                        style={{
                                            borderBottom: 'solid 3px blue',
                                            background: 'aliceblue',
                                            color: 'black',
                                            fontWeight: 'bold',
                                        }}>
                                        {column.render("Header")}
                                        <span>

                            </span>
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row: any) => {
                        prepareRow(row)
                        let s = { backgroundColor: 'white' }
                        return (
                            <tr {...row.getRowProps()} style={row.depth === 1 ? s : {}}>
                                {row.cells.map((cell: any) => {
                                    return (
                                        <td {...cell.getCellProps()} className={cell.column.tdClassName}>{cell.render('Cell')}</td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            {showPagination && (<>
                <div className="table-pagination"
                     style={{margin: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous
                    </button>
                    <span>
                    <strong style={{display: 'block', width: '100px', textAlign: 'center'}}>
                        {currentPageIndex + 1} / {pageOptions.length}
                    </strong>
                </span>
                    <span className="table-pagination-goto">
                    Go to page: {' '}
                        <input type="number" defaultValue={currentPageIndex + 1}
                               onChange={(e) => {
                                   const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                   gotoPage(pageNumber)
                               }}
                               style={{width: '50px'}}/>
                </span>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                    </button>
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </button>
                </div>
                <div className="table-pagesize"
                     style={{margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <select value={currentPageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                        {
                            [10, 25, 50].map(size => (
                                <option key={size} value={size}>
                                    {size} records
                                </option>
                            ))
                        }
                    </select>
                </div>
            </>)}


        </div>
    );
}

export default CFAdminDataSelectTable;