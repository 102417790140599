import AuthService from '../services/AuthService';

//const sleep = (ms: number | undefined) => new Promise(r => setTimeout(r, ms));

export const retrieveUrl = async () => {
  AuthService.getUrl()
  .then(async (response: any) => {

    if (response?.data?.configUrl){
      window.location.replace(response.data.configUrl);
    }
  })
  .catch((e: any) => {
    console.log(e);
  });
};

export const retrieveUrlParams= async () => {

  const queryParams : any = new URLSearchParams(window.location.search);

  const code = queryParams.get("code")
  if (code != null) {
    return code;
  }else{
    return null;
  }
  
};

export const clearTokens= async () => {

  window.localStorage.removeItem("awsmodtoken");
  window.localStorage.removeItem("rwsmodtoken");
};

export const isLogged= async () => {

  if(window.localStorage.getItem("awsmodtoken") !=null && window.localStorage.getItem("rwsmodtoken") !=null){
    return true;
  }
  return false;
};

export const setUserData= async (user : any) => {

  window.localStorage.setItem("user", user);
};

export const setUserTokens= async (awsmodtoken : any, rwsmodtoken: any) => {

  window.localStorage.setItem("awsmodtoken", awsmodtoken) ;
  window.localStorage.setItem("rwsmodtoken", rwsmodtoken) ;
};

export const is401Error= async (e : any) => {

  if(e.response && e.response.status === 401) return true;
  return false;
};