import React from 'react';
import EditIcon from "../../assets/images/icons8-edit.svg"
import DeleteIcon from "../../assets/images/delete.svg"
import DeleteIconDisabled from "../../assets/images/delete-disabled.svg"

export const EditButton =  ({ cell: { row: { original: originalRow }, index: rowIndex }, handleChange}: any) => {
    return (
        <div>
            <button data-testid="cell-edit-button" className="edit-button" onClick={() => handleChange({ originalRow, rowIndex })} title="Edit">
                <img src={EditIcon} alt="Xcelvations Logo" height="15" />
            </button>
        </div>
    )
}

export const DeleteButton = ({ cell: { row: { original: originalRow }, index: rowIndex }, handleDelete, disabled}: any) => {
    return (
        <div>
            <button data-testid="cell-delete-button" className="edit-button" onClick={() => handleDelete({originalRow, rowIndex})} disabled={disabled}  title={disabled ? "In Use" : "Delete"}>
                <img src={disabled ? DeleteIconDisabled : DeleteIcon} alt="Xcelvations Logo" height="15"/>
            </button>
        </div>
    )
}
export const CheckboxButton = ({ cell: { row: { original: originalRow }, index: rowIndex }, handleChange, checked}: any) => {
    return (
        <div>
            <input type="checkbox" checked={checked} onChange={(event)=> handleChange({originalRow, rowIndex, checked: event.target.checked})} />
        </div>
    );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
export const CellAnchorButton =  ({ cell: { row: { original: originalRow }, index: rowIndex }, handleChange}: any) => {
    return (
        <div>
            <button data-testid="cell-edit-button" className="edit-button" onClick={() => handleChange({ originalRow, rowIndex })} title="Edit">
                <a href="#"> {originalRow.id} </a>
            </button>
        </div>
    )
}