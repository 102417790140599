import React, {useEffect, useState} from "react";
import SelectIcon from "../../assets/images/select_check_Blue.svg";

function CFAdminMaintenanceScheduleForm ({scheduleMaintenance, vehicleDetails} : any) {

    const [columnValues, setColumnValues] = useState<any>([]);
    const [dataValues, setDataValues] = useState<any>([]);
    const [recommendedOperations, setRecommendedOperations] = useState<any>([]);
    const [valuePackagePrice, setValuePackagePrice] = useState<any>();
    const [recommendationsByMileageColumnValues, setRecommendationsByMileageColumnValues] = useState<any>([]);
    const [recommendationsByMileageDataValues, setRecommendationsByMileageDataValues] = useState<any>([]);
    const [recommendationsAtMileage, setRecommendationsAtMileage] = useState<any>([]);

    function getVehicleConfigurations(){

        if (scheduleMaintenance) {

            createColumnsJson(scheduleMaintenance.attributes);
            createDataJson(scheduleMaintenance.attributes);
            if(scheduleMaintenance.iolmRecommendations){
                setRecommendedOperations(scheduleMaintenance.iolmRecommendations?.recommendedOperations);
                setValuePackagePrice(scheduleMaintenance.iolmRecommendations?.valuePackagePrice)
            }
            if(scheduleMaintenance.recommendationsByMileage){
                createRecommendationsByMileageColumnJson(scheduleMaintenance.recommendationsByMileage);
                createRecommendationsByMileageDataJson(scheduleMaintenance.recommendationsByMileage);
            }
            if(scheduleMaintenance.recommendationsAtMileage){
                setRecommendationsAtMileage(scheduleMaintenance.recommendationsAtMileage);
            }
        }
    }

    function createRecommendationsByMileageColumnJson(recommendationsByMileage: any)
    {
        let jsonData : any = [];
        jsonData.push('Mileage:');
        recommendationsByMileage.forEach((childElement: any) => {
            jsonData.push(childElement.scheduleDesc);
        });

        setRecommendationsByMileageColumnValues(jsonData);
        return jsonData;
    }

    function createRecommendationsByMileageDataJson(recommendationsByMileage: any)
    {
        let opCodes : any = [];
        let dataValues : any = [];
        let columnId = 'OpCode';
        let columnMileageName = 'Mileage:';
        let opCodeMap : Record<string, []> = {};

        let opCodeDataValues : any = [];
        recommendationsByMileage.forEach((childElement: any) => {
            childElement.recommendedOperations.forEach((recommendedOperation: any) => {
                if (!opCodeMap[recommendedOperation.operationCode]) {
                    opCodeDataValues = [];
                }else{
                    opCodeDataValues = opCodeMap[recommendedOperation.operationCode];
                }
                opCodeDataValues.push(childElement.scheduleDesc);
                opCodeMap[recommendedOperation.operationCode] = opCodeDataValues;
            });
        });

        let jsonValuePackData : any = {};
        jsonValuePackData[columnMileageName] = 'Value Package Price:';
        recommendationsByMileage.forEach((childElement: any) => {
            let columnScheduleName = childElement.scheduleDesc;
            jsonValuePackData[columnScheduleName] = childElement.valuePackagePrice;
        });
        dataValues.push(jsonValuePackData);


        recommendationsByMileage.forEach((childElement: any) => {
            childElement.recommendedOperations.forEach((recommendedOperation: any) => {
                if(!opCodes.includes(recommendedOperation.operationCode)){
                    let jsonData : any = {};
                    opCodes.push(recommendedOperation.operationCode);
                    jsonData[columnId] = recommendedOperation.operationCode
                    jsonData[columnMileageName] = recommendedOperation.operationDescription + " (" + recommendedOperation.operationCode + ") -" + recommendedOperation.operationPrice;
                    let opCodeDataValues = opCodeMap[recommendedOperation.operationCode];
                    opCodeDataValues.forEach((scheduleDesc: string) => {
                        jsonData[scheduleDesc] = 'Yes'
                    });
                    dataValues.push(jsonData);
                }
            });
        });

        //dataValues.push(jsonData);
        setRecommendationsByMileageDataValues(dataValues);
        return dataValues;
    }

    function createColumnsJson(attributes: any)
    {
        let jsonData : any = [];
        attributes.forEach((childElement: any) => {
            jsonData.push(childElement.attributeDescription);
        });

        setColumnValues(jsonData);
        return jsonData;
    }

    function createDataJson(attributes: any)
    {
        let dataValues : any = [];
        let jsonData : any = {};
        attributes.forEach((childElement: any) => {
            let columnName = childElement.attributeDescription;
            jsonData[columnName] = childElement.attributeValueDescription;
        });

        dataValues.push(jsonData);
        setDataValues(dataValues);
        return jsonData;
    }


    useEffect(() => {
        getVehicleConfigurations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduleMaintenance]);

    const renderVehicleTable = (columnValues : any , dataValues: any) => {
        return (
            <table className="inner-table">
                <thead>
                <tr>
                    {columnValues.map((heading: any) => (
                        <th style={{background: 'aliceblue', color:'black'}} key={heading}>
                            {heading} </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {dataValues.map((row : any, rowIndex:any) => (
                    <tr key={rowIndex}>
                        {columnValues.map((heading: any) => (
                            <td style={{maxWidth: '250px'}} key={row[heading]}>
                                {row[heading] === 'Yes' ? <img src={SelectIcon} alt="Xcelvations Logo"/> : row[heading]}</td>
                ))}
                </tr>
                ))}
                </tbody>
            </table>
        );
    };

    const renderRecommendationsAtMileage = () => {
        return (
            <table className="inner-table">
                <tbody>
                {recommendationsAtMileage.recommendedOperations.map((row: any, rowIndex: any) => (
                    <tr key={rowIndex}>
                        <td style={{maxWidth: '50px'}}><img src={SelectIcon} alt="Xcelvations Logo"/></td>
                        <td style={{maxWidth: '50px'}}>{row.operationCode}</td>
                        <td style={{maxWidth: '250px'}}>{row.operationDescription}</td>
                        <td style={{maxWidth: '50px'}}>{row.operationPrice}</td>
                    </tr>
                ))}
                <tr>
                    <td colSpan={3}>Total:</td>
                    <td style={{maxWidth: '50px'}}>{recommendationsAtMileage.totalPrice}</td>
                </tr>
                <tr>
                    <td colSpan={3}>Value Package Price:</td>
                    <td style={{maxWidth: '50px'}}>{recommendationsAtMileage.valuePackagePrice}</td>
                </tr>
                </tbody>
            </table>
        );
    };

    const renderRecommendedOperations = () => {
        return (
            <>
            {
                    recommendedOperations && recommendedOperations.length > 0 &&
                    <div>
                        <ul>
                            {recommendedOperations.map((recommendedOperation: any, index: number) => (
                                <li key={index}>{recommendedOperation.operationDescription} ({recommendedOperation.operationCode}) - {recommendedOperation.operationPrice}</li>
                            ))}
                        </ul>
                        <div className="main-home-ext2c">
                            <b>Oil Change Value Package Price: </b>{valuePackagePrice}
                        </div>
                    </div>

                }
            </>
        );
    };

    return (
        <>
            <div className="main-home-container">
                <div className="main-home-content">
                    <div className="main-home">
                        <div>
                            <div className="main-home-ext1">
                                <b>Scheduled Maintenance: {scheduleMaintenance.mmyDescription}</b> <div className="main-home-table-sub" > {vehicleDetails.vin === '' ? '' : "VIN: " + vehicleDetails.vin} </div>
                            </div>
                            <div className="main-home-ext2">
                                <b>Driving Condition:</b> {scheduleMaintenance.drivingConditionDesc}
                            </div>
                            <div className="main-home-table">
                                {renderVehicleTable(columnValues, dataValues)}
                            </div>
                            { vehicleDetails && vehicleDetails.scheduleCode === 'FS' &&
                                <div className="main-home-ext1">
                                    <b> Ford Recommended Maintenance </b>
                                </div>
                            }
                            { recommendationsAtMileage && recommendationsAtMileage.recommendedOperations && recommendationsAtMileage.recommendedOperations.length > 1 &&
                                <div className="main-home-ext1">
                                    <b> Ford Recommended Maintenance - {recommendationsAtMileage.scheduleDesc} </b>
                                </div>
                            }
                            { vehicleDetails && vehicleDetails.scheduleCode === 'FS' && recommendedOperations && recommendedOperations.length > 0 &&
                                <>
                                    <div className="main-home-ext1">
                                        <b> Ford Recommended Maintenance as indicated by Intelligent Oil Life Monitor
                                            System</b>
                                    </div>
                                    <div className="main-home-ext2">
                                        <b>This vehicle is equipped with Intelligent Oil Life Monitor (IOLM).
                                            Based on driving conditions, the IOLM will display a message when your
                                            vehicle needs
                                            an oil
                                            change.
                                            Every time the display reads 'Oil Change Required', the following
                                            maintenance is
                                            recommended:</b>
                                    </div>
                                    <div className="main-home-table">
                                        {renderRecommendedOperations()}
                                    </div>
                                </>
                            }
                            { vehicleDetails && vehicleDetails.scheduleCode !== 'FS' && recommendedOperations && recommendedOperations.length > 0 &&
                                <>
                                    <div className="main-home-ext1">
                                        <b> Ford Recommended Maintenance - Every IOLM Oil Change</b>
                                    </div>
                                    <div className="main-home-ext1">
                                        <b>Is it time for an Oil Change? Check percentage oil life remaining in the
                                            Message Center.</b>
                                    </div>
                                    <div className="main-home-ext2">
                                        This vehicle is equipped with Intelligent Oil Life Monitor (IOLM).
                                        Based on driving conditions, the IOLM will display a message when your vehicle needs an oil change.
                                        If the display is showing <b>Oil Change Required</b>, It is time!

                                    </div>
                                    <div className="main-home-table">
                                        {renderRecommendedOperations()}
                                    </div>
                                </>
                            }
                            {recommendationsAtMileage && recommendationsAtMileage.recommendedOperations && recommendationsAtMileage.recommendedOperations.length > 0 &&
                                <>
                                    <div className="main-home-ext2">
                                        <b>Ford Recommended Operations</b>
                                    </div>
                                    <div className="main-home-table">
                                        {renderRecommendationsAtMileage()}
                                    </div>
                                </>
                        }
                        <div>
                            {renderVehicleTable(recommendationsByMileageColumnValues, recommendationsByMileageDataValues)}
                            </div>
                            <div className="main-home-ext2n">
                                {scheduleMaintenance.notes}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CFAdminMaintenanceScheduleForm;
