import React, {useEffect, useRef, useState} from 'react';
import CFAdminDataTable from "../../components/CFAdminDataTable";
import CFAdminModal from "../../components/CFAdminModal";
import './CFAdminBundles.scss'
import CFAdminBundlesForm from './CFAdminBundlesForm';
import CFAdminNotification from '../../components/CFAdminNotification';
import BundleService from "../../services/BundleService";
import {DeleteButton, EditButton} from "../../components/CFAdminDataTable/ActionsButtonComponents";

const renderEditButton = (props: any, handleChange: Function) => {
    return <EditButton {...props} handleChange={handleChange}/>
}

const renderDeleteButton = (props: any, handleDelete: Function, disabled: boolean=false) => {
    return <DeleteButton {...props} handleDelete={handleDelete} disabled={disabled}/>
}

export type BundleOperation = {
    operationId: number;
    operationCode: string;
    operationDescription: string;
    sortKey: number;
    userKey: string;
    modified: string;
}

export type Bundle = {
    bundleId: number;
    bundleCode: string;
    bundleDescription: string;
    countryLanguageTextId?: number;
    operations: BundleOperation[];
    userKey: string;
    modified: string;
};

function CFAdminBundles() {
    const childCompRef = useRef<any>();
    const [data, setData] = useState<Bundle[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [bundleDetails, setBundleDetails] = useState<Bundle>({
        bundleId: 0,
        bundleCode: "",
        bundleDescription: "",
        operations: [],
        userKey: "",
        modified:""
    });
    const [bundleOperations, setBundleOperations] = useState<BundleOperation[]>([]);

    const columns = [
        {
            Header: "Bundle Code",
            accessor: 'bundleCode'
        },
        {
            Header: "Bundle Description",
            accessor: 'bundleDescription'
        },
        {
            Header: "Last Modified By",
            accessor: 'userKey'
        },
        {
            Header: "Last Modified Date",
            accessor: 'modified'
        },
        {
            Header: "Actions",
            Cell: (props: any) => {
                const {row} = props;
                const inUse = row.original.usedFlag;
                return  (
                    <div className="cell-buttons">
                        {renderEditButton(props, handleChange)}
                        {renderDeleteButton(props, handleDelete, inUse)}
                    </div>
                )}
        },
    ];

    const showEditStepperDialog = (event: any) => {
        setBundleDetails({
            ...bundleDetails,
            bundleId: event.originalRow.bundleId,
            bundleCode: event.originalRow.bundleCode,
            bundleDescription: event.originalRow.bundleDescription
        });
        setBundleOperations(()=> event.originalRow.operations);
        setIsOpen(!isOpen);
    }

    const handleChange = (event: any) => {
        setIsDelete(false);
        showEditStepperDialog(event);
    }

    const handleDelete = (event: any) => {
        setIsDelete(true);
        showEditStepperDialog(event);
    }

    useEffect(() => {
        retrieveBundles();
    }, []);

    useEffect(() => {
        if (!isOpen) {
            setIsDelete(false);
        }
    }, [isOpen,isDelete]);


    const retrieveBundles = async () => {
        BundleService.getAll()
            .then(response => {

                const bundlesResponseModel = response.data?.bundlesResponseModel;
                if (bundlesResponseModel) {
                    const bundles: Bundle[] = bundlesResponseModel.map((bundle: any) => ({
                        bundleId: bundle.id,
                        bundleCode: bundle.bundleCode,
                        bundleDescription: bundle.bundleDescription,
                        countryLanguageTextId: bundle.countryLanguageTextId,
                        userKey: bundle.userKey,
                        modified: bundle.modified,
                        operations: bundle.operationBundles?.map((operation: BundleOperation) => ({
                            operationId: operation.operationId,
                            operationCode: operation.operationCode,
                            operationDescription: operation.operationDescription,
                            sortKey: operation.sortKey
                        })) || []
                    }));
                    setData(bundles);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const confirmSuccessMessage = async (value: any) => {
        childCompRef.current.successMessage(value);
    }
    const confirmErrorMessage = async (value: any) => {
        childCompRef.current.failureMessage(value);
    };

    const toggleModal = () => {
        setBundleDetails({
            ...bundleDetails,
            bundleId: 0,
            bundleCode: '',
            bundleDescription: '',
            operations: []
        });
        setBundleOperations([]);
        setIsOpen(!isOpen);
    }


    return (

        <div className="bundle-component">
            <h2 className="fomo-type--title">Manage Bundles</h2>
            <CFAdminModal
                isOpen={isOpen}
                toggle={toggleModal}
                isAdd={true}
                title={'Add Bundle'}
            >
                <CFAdminBundlesForm
                    bundleId={bundleDetails?.bundleId}
                    bundleCode={bundleDetails.bundleCode}
                    bundleDescription={bundleDetails.bundleDescription}
                    operations={bundleOperations}
                    userKey={bundleDetails.userKey}
                    modified={bundleDetails.modified}
                    isDelete={isDelete}
                    toggleModal={toggleModal}
                    refreshData={retrieveBundles}
                    successMessage={confirmSuccessMessage}
                    errorMessage={confirmErrorMessage}/>

            </CFAdminModal>

            <CFAdminDataTable
                columns={columns}
                data={data}
                toggleModal={toggleModal}
                textModal={'Add Bundle'}
            />
            <CFAdminNotification ref={childCompRef}/>
        </div>
    );
}

export default CFAdminBundles;